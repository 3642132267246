<template>
  <el-dialog
    :title="classHandleDialogTitle"
    :visible.sync="visible"
    :classHandleDialogVisible="classHandleDialogVisible"
    :append-to-body="true"
    :destroy-on-close="true"
    @before-close="handleClassClose"
    @close="handleClassClose"
    width="50%"
  >
    <el-form
      class="dialog-form"
      :model="classHandleDialogForm"
      ref="classHandleDialogForm"
      :rules="rules"
      label-width="6.25vw"
    >
      <el-row>
        <el-col :span="24">
          <el-form-item
            class="form-choose-item"
            label="选择年级："
            prop="gradeName"
          >
            <el-select
              class="form-input"
              v-model="classHandleDialogForm.gradeName"
              clearable
              placeholder="请选择年级"
            >
              <el-option
                v-for="item in gradeOptions"
                :key="item.value"
                :label="item.name"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item
            class="form-choose-item"
            label="选择班级："
            prop="className"
          >
            <el-select
              class="form-input"
              v-model="classHandleDialogForm.className"
              clearable
              placeholder="请选择班级"
            >
              <el-option
                v-for="item in classOptions"
                :key="item.value"
                :label="item.name"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item
            class="form-choose-item"
            label="日期："
            prop="classDate"
          >
            <el-date-picker
              v-model="classHandleDialogForm.classDate"
              type="date"
              placeholder="请选择日期"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item class="form-choose-item" label="原因：" prop="reason">
            <el-input
              class="form-input"
              type="textarea"
              :autosize="{ minRows: 2, maxRows: 4 }"
              placeholder="请填写原因内容..."
              v-model="classHandleDialogForm.reason"
              resize="none"
              :maxlength="100"
              show-word-limit
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button :loading="buttonloading" @click="handleClassClose"
        >取 消</el-button
      >
      <el-button
        :loading="buttonloading"
        type="primary"
        @click="handleClassSubmit"
        >保 存</el-button
      >
    </div>
  </el-dialog>
</template>
<script>
export default {
  name: "classHandleDialog",
  props: {
    classHandleDialogVisible: {
      type: Boolean,
      default: false,
    },
    classHandleDialogTitle: {
      type: String,
      default: "",
    },
    classHandleDialogType: {
      type: String,
      default: "",
    },
  },
  watch: {
    classHandleDialogVisible(val) {
      this.visible = val;
    },
    classHandleDialogType(val) {},
  },
  data() {
    return {
      visible: false, //弹窗标识
      buttonloading: false, //按钮loading
      classHandleDialogForm: {
        gradeName: "",
        className: "",
        classDate: "",
        reason: "",
      }, //表单内容
      rules: {
        gradeName: [
          { required: true, message: "请选择年级", trigger: "change" },
        ],
        className: [
          { required: true, message: "请选择班级", trigger: "change" },
        ],
        classDate: [
          { required: true, message: "请选择日期", trigger: "change" },
        ],
        reason: [{ required: true, message: "请输入原因", trigger: "blur" }],
      }, //表单规则
    };
  },
  methods: {
    //弹窗关闭
    handleClassClose() {
      this.classHandleDialogForm = {
        gradeName: "",
        className: "",
        classDate: "",
        reason: "",
      };
      this.buttonloading = false;
      this.$emit("refreshPage", false);
    },
    //提交弹窗
    handleClassSubmit() {
      this.$refs.classHandleDialogForm.validate((valid) => {
        if (valid) {
          this.buttonloading = true;
          let params = {
            gradeName: this.classHandleDialogForm.gradeName,
            className: this.classHandleDialogForm.className,
            classDate: this.classHandleDialogForm.classNum,
            reason: this.classHandleDialogForm.reason,
          };
          let url = "";
          if (this.handleType == "add") {
            url = "/api/educourse/save";
          } else {
            params.id = this.courseId;
            url = "/api/educourse";
          }
          // this.$api.courseHandle(url, params, this.handleType).then((res) => {
          //   if (res.data.code === 0) {
          //     this.$message({
          //       message: "保存成功",
          //       type: "success",
          //       duration: 2000,
          //       onClose: () => {
          //         this.buttonloading = false;
          //         if (this.handleType == "add") {
          //           this.courseSettingDialogVisible = true;
          //           this.$nextTick(() => {
          //             this.courseId = res.data.data;
          //             this.courseSettingType = "introduce";
          //           });
          //         } else {
          //           this.pageLoading = true;
          //           this.getData();
          //         }
          //         this.handleClose();
          //       },
          //     });
          //   } else {
          //     this.buttonloading = false;
          //     this.$message.error(res.data.msg);
          //   }
          // });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>